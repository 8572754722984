import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, Form, Input, Button, message } from 'antd';
import { graphql, compose } from 'react-apollo';
import { navigate } from '@reach/router';

import MUTATION from '../graphql/mutations/userUpdatePasswordMutation.graphql';

const FormItem = Form.Item;

class UpdatePasswordPage extends Component {
  state = {
    submittingForm: false,
  };

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({
      submittingForm: true,
    });

    const { form, mutate } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        mutate({ variables: values })
          .then(() => {
            message.success('Password updated');
            navigate('/');
          })
          .catch((mutateErr) => {
            this.setState({
              submittingForm: false,
            });
            console.log(mutateErr);
            message.error(mutateErr.message);
          });
      } else {
        this.setState({
          submittingForm: false,
        });
      }
    });
  };

  compareNewPasswords = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('newPassword')) callback('Passwords do not match');
    else callback();
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { submittingForm } = this.state;
    return (
      <div
        style={{
          width: '100%',
          height: 'calc(100vh - 56px)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Card title="Update your password" style={{ width: 300 }}>
          <Form onSubmit={this.handleSubmit}>
            <FormItem hasFeedback label="Old Password">
              {getFieldDecorator('oldPassword', {
                rules: [{ required: true, message: 'Field is required!' }],
              })(<Input type="password" disabled={submittingForm} />)}
            </FormItem>
            <FormItem hasFeedback label="New Password">
              {getFieldDecorator('newPassword', {
                rules: [{ required: true, message: 'Field is required!' }],
              })(<Input type="password" disabled={submittingForm} />)}
            </FormItem>
            <FormItem hasFeedback label="Confirm New Password">
              {getFieldDecorator('newPasswordConfirm', {
                rules: [{ required: true, message: 'Field is required!' }, { validator: this.compareNewPasswords }],
              })(<Input type="password" disabled={submittingForm} />)}
            </FormItem>
            <FormItem>
              <Button type="primary" htmlType="submit" loading={submittingForm}>
                Confirm
              </Button>
            </FormItem>
          </Form>
        </Card>
      </div>
    );
  }
}

UpdatePasswordPage.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFieldsAndScroll: PropTypes.func,
    resetFields: PropTypes.func,
  }).isRequired,
  mutate: PropTypes.func.isRequired,
};

export default compose(
  graphql(MUTATION),
  Form.create(),
)(UpdatePasswordPage);
