import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { Spin } from 'antd';
import BuyerForm from '../forms/BuyerForm';

import getBuyerByIdQuery from '../graphql/queries/getBuyerByIdQuery.graphql';

const BuyerPage = ({ id }) => (
  <div className="contentBox">
    <h1>View merchant information</h1>
    <Query variables={{ id: parseInt(id, 10) }} query={getBuyerByIdQuery} fetchPolicy="network-only">
      {({ loading, error, data }) => {
        if (loading) return <Spin />;
        if (error) return <p>{error.message}</p>;
        const { buyer } = data;
        return <BuyerForm mode="view" savedData={buyer} />;
      }}
    </Query>
  </div>
);

BuyerPage.propTypes = {
  id: PropTypes.string,
};

BuyerPage.defaultProps = {
  id: null,
};

export default BuyerPage;
