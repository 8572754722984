import React, { Component, Fragment } from 'react';
import { Query } from 'react-apollo';
import { Spin, Select } from 'antd';

import createSellerShipmentPageQuery from '../graphql/queries/createSellerShipmentPageQuery.graphql';
import buyerSellerPlacedOrdersQuery from '../graphql/queries/buyerSellerPlacedOrdersQuery.graphql';
import CreateSellerShipmentForm from '../forms/CreateSellerShipmentForm';

const { Option } = Select;

class CreateSellerShipmentPage extends Component {
  state = {
    selectedBuyerId: undefined,
    selectedSellerId: undefined,
  };

  handleBuyerSelect = (value) => {
    this.setState({ selectedBuyerId: value });
  };

  handleSellerSelect = (value) => {
    this.setState({ selectedSellerId: value });
  };

  render() {
    const { selectedBuyerId, selectedSellerId } = this.state;
    return (
      <div className="contentBox">
        <h1>Create new shipment</h1>
        <Query query={createSellerShipmentPageQuery} fetchPolicy="network-only">
          {({ loading, error, data }) => {
            if (loading) return <Spin />;
            if (error)
              return (
                <p>
                  <strong>ERROR: </strong>
                  {error.message}
                </p>
              );
            const { buyers, sellers } = data;
            return (
              <Fragment>
                <div className="row" style={{ marginBottom: 20 }}>
                  <div className="col-6">
                    <p style={{ marginBottom: 5 }}>Miller:</p>
                    <Select
                      allowClear
                      style={{ width: '100%' }}
                      showSearch
                      optionFilterProp="children"
                      onChange={this.handleSellerSelect}
                      value={selectedSellerId}
                      placeholder="Select miller"
                    >
                      {sellers.map((entry) => (
                        <Option key={entry.id} value={entry.id}>
                          {entry.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className="col-6">
                    <p style={{ marginBottom: 5 }}>Merchant:</p>
                    <Select
                      allowClear
                      style={{ width: '100%' }}
                      showSearch
                      optionFilterProp="children"
                      onChange={this.handleBuyerSelect}
                      value={selectedBuyerId}
                      placeholder="Select merchant"
                    >
                      {buyers.map((entry) => (
                        <Option key={entry.id} value={entry.id}>
                          {entry.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                {selectedBuyerId && selectedSellerId ? (
                  <Query
                    query={buyerSellerPlacedOrdersQuery}
                    variables={{ buyerId: selectedBuyerId, sellerId: selectedSellerId }}
                    fetchPolicy="network-only"
                  >
                    {({ loading: q2Loading, error: q2Error, data: q2Data }) => {
                      if (q2Loading) return <Spin />;
                      if (q2Error) {
                        return (
                          <p>
                            <strong>ERROR: </strong>
                            {q2Error.message}
                          </p>
                        );
                      }
                      const { orders } = q2Data;
                      return (
                        <CreateSellerShipmentForm
                          buyerId={selectedBuyerId}
                          sellerId={selectedSellerId}
                          orders={orders}
                        />
                      );
                    }}
                  </Query>
                ) : null}
              </Fragment>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default CreateSellerShipmentPage;
