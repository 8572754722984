import React from 'react';
import { Router } from '@reach/router';

import { loginPagePath } from './uitls/globals';

import LoginPage from './pages/LoginPage';
import App from './layouts/AppLayout';
import UpdatePasswordPage from './pages/UpdatePasswordPage';
import HomePage from './pages/HomePage';
import AdminDashboard from './pages/AdminDashboard';
import StaffDashboard from './pages/StaffDashboard';
import AllSellersPage from './pages/AllSellersPage';
import CreateSellerPage from './pages/CreateSellerPage';
import SellerPage from './pages/SellerPage';
import EditSellerPage from './pages/EditSellerPage';
import AllBuyersPage from './pages/AllBuyersPage';
import CreateBuyerPage from './pages/CreateBuyerPage';
import CreateSellerShipmentPage from './pages/CreateSellerShipmentPage';
import EditBuyerPage from './pages/EditBuyerPage';
import BuyerPage from './pages/BuyerPage';

const Routes = () => (
  <Router>
    <LoginPage path={loginPagePath} />
    <App path="/">
      <UpdatePasswordPage path="auth/password/update" />
      <AdminDashboard path="admin" />
      <StaffDashboard path="staff" />
      {/* Sellers */}
      <AllSellersPage path="sellers" />
      <CreateSellerPage path="sellers/create" />
      <SellerPage path="sellers/:id" />
      <EditSellerPage path="sellers/:id/edit" />
      {/* Buyers */}
      <AllBuyersPage path="buyers" />
      <CreateBuyerPage path="buyers/create" />
      <BuyerPage path="buyers/:id" />
      <EditBuyerPage path="buyers/:id/edit" />
      {/* Shipments */}
      <CreateSellerShipmentPage path="shipments/create" />
      {/* Catch all */}
      <HomePage path="/*" />
    </App>
  </Router>
);

export default Routes;
