import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Input, message, InputNumber } from 'antd';
import validationRegex from '@eumentis-cloud/js-validation-regex';
import { graphql, compose } from 'react-apollo';
import { formItemLayout, log, textAreaRemoveNewline } from '../uitls/globals';

import createEditSellerMutation from '../graphql/mutations/createEditSellerMutation.graphql';

const FormItem = Form.Item;
const { TextArea } = Input;

class SellerForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submittingForm: false,
      mode: props.mode,
    };
  }

  componentDidMount() {
    const { savedData, form } = this.props;
    if (savedData) {
      const { id, __typename, createdAt, ...formData } = savedData;
      form.setFieldsValue(formData);
    }
  }

  handleEditButton = () => {
    this.setState({ mode: 'edit' });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ submittingForm: true });

    const { form, mutate, savedData } = this.props;
    const { mode } = this.state;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const valuesToSubmit = { ...values };
        valuesToSubmit.streetAddress = textAreaRemoveNewline(valuesToSubmit.streetAddress);
        if (savedData && Number.isInteger(savedData.id) && mode === 'edit') valuesToSubmit.id = savedData.id;
        mutate({ variables: valuesToSubmit })
          .then(() => {
            this.setState({ submittingForm: false, mode: 'view' });
            message.success('Miller created!');
            if (mode === 'create') window.history.back();
          })
          .catch((mutateErr) => {
            log(mutateErr);
            message.error(mutateErr.message);
            this.setState({ submittingForm: false });
          });
      } else {
        this.setState({ submittingForm: false });
      }
    });
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { submittingForm, mode } = this.state;

    const formDisabled = submittingForm || mode === 'view';

    return (
      <div>
        <Form onSubmit={this.handleSubmit}>
          <FormItem label="Miller Name" hasFeedback={!formDisabled} {...formItemLayout}>
            {getFieldDecorator('name', {
              rules: [{ required: true, message: 'Field is required' }],
            })(<Input disabled={formDisabled} required />)}
          </FormItem>
          <FormItem label="Address" hasFeedback={!formDisabled} {...formItemLayout}>
            {getFieldDecorator('streetAddress', {
              rules: [{ required: true, message: 'Field is required' }],
            })(<TextArea autosize={{ minRows: 3, maxRows: 5 }} disabled={formDisabled} required />)}
          </FormItem>
          <FormItem label="City" hasFeedback={!formDisabled} {...formItemLayout}>
            {getFieldDecorator('city', { rules: [{ required: true, message: 'Field is required' }] })(
              <Input disabled={formDisabled} required />,
            )}
          </FormItem>
          <FormItem label="State" hasFeedback={!formDisabled} {...formItemLayout}>
            {getFieldDecorator('state', { rules: [{ required: true, message: 'Field is required' }] })(
              <Input disabled={formDisabled} required />,
            )}
          </FormItem>
          <FormItem label="Pincode" hasFeedback={!formDisabled} {...formItemLayout}>
            {getFieldDecorator('pincode', {
              rules: [
                { required: true, message: 'Field is required' },
                { pattern: validationRegex.pincode, message: 'Incorrect value' },
              ],
            })(<Input disabled={formDisabled} required />)}
          </FormItem>
          <FormItem label="GSTIN" hasFeedback={!formDisabled} {...formItemLayout}>
            {getFieldDecorator('gstin', {
              rules: [
                { required: true, message: 'Field is required' },
                { pattern: validationRegex.gstin, message: 'Incorrect value' },
              ],
            })(<Input disabled={formDisabled} required />)}
          </FormItem>
          <FormItem label="FSSAI no." {...formItemLayout}>
            {getFieldDecorator('fssai')(<Input disabled={formDisabled} />)}
          </FormItem>
          <FormItem label="Company Email" hasFeedback={!formDisabled} {...formItemLayout}>
            {getFieldDecorator('email', {
              rules: [{ type: 'email', message: 'Incorrect value' }],
            })(<Input disabled={formDisabled} type="email" />)}
          </FormItem>
          <FormItem
            label="Company Landline"
            extra="Landline no. with STD code: 08066666666"
            hasFeedback={!formDisabled}
            {...formItemLayout}
          >
            {getFieldDecorator('telephone', {
              rules: [{ pattern: validationRegex.landline, message: 'Incorrect value' }],
            })(<Input disabled={formDisabled} type="tel" />)}
          </FormItem>
          <FormItem label="Brokerage Rate (per quintal)" hasFeedback={!formDisabled} {...formItemLayout}>
            {getFieldDecorator('brokerageRatePerQuintal', {
              rules: [
                { required: true, message: 'Field is required' },
                { pattern: validationRegex.float, message: 'Incorrect value' },
              ],
            })(<InputNumber disabled={formDisabled} required />)}
          </FormItem>

          <h3 style={{ textDecoration: 'underline' }}>Primary Contact Person Details</h3>

          <FormItem label="Full Name" hasFeedback={!formDisabled} {...formItemLayout}>
            {getFieldDecorator('primaryContactName', { rules: [{ required: true, message: 'Field is required' }] })(
              <Input disabled={formDisabled} required />,
            )}
          </FormItem>
          <FormItem label="Mobile" hasFeedback={!formDisabled} {...formItemLayout} extra="The 10 digit mobile no.">
            {getFieldDecorator('primaryContactMobile', {
              rules: [
                { required: true, message: 'Field is required' },
                { pattern: validationRegex.mobile, message: 'Incorrect value' },
              ],
            })(<Input disabled={formDisabled} required addonBefore="+91" />)}
          </FormItem>
          <FormItem label="Email" hasFeedback={!formDisabled} {...formItemLayout}>
            {getFieldDecorator('primaryContactEmail', {
              rules: [{ required: true, message: 'Field is required' }, { type: 'email', message: 'Incorrect value' }],
            })(<Input disabled={formDisabled} type="email" required />)}
          </FormItem>

          <h3 style={{ textDecoration: 'underline' }}>Bank Details</h3>
          <FormItem
            label="Cheque Pay Name"
            hasFeedback={!formDisabled}
            extra="The name to write on payment cheques"
            {...formItemLayout}
          >
            {getFieldDecorator('bankDetails.chequePayName', {
              rules: [{ required: true, message: 'Field is required' }],
            })(<Input disabled={formDisabled} required />)}
          </FormItem>
          <FormItem label="A/C no." hasFeedback={!formDisabled} {...formItemLayout}>
            {getFieldDecorator('bankDetails.accountNo', {
              rules: [{ required: true, message: 'Field is required' }],
            })(<Input disabled={formDisabled} required />)}
          </FormItem>
          <FormItem label="Bank Name" hasFeedback={!formDisabled} {...formItemLayout}>
            {getFieldDecorator('bankDetails.bank', { rules: [{ required: true, message: 'Field is required' }] })(
              <Input disabled={formDisabled} required />,
            )}
          </FormItem>
          <FormItem label="Bank Branch" hasFeedback={!formDisabled} {...formItemLayout}>
            {getFieldDecorator('bankDetails.branch', { rules: [{ required: true, message: 'Field is required' }] })(
              <Input disabled={formDisabled} required />,
            )}
          </FormItem>
          <FormItem label="IFCS code" hasFeedback={!formDisabled} {...formItemLayout}>
            {getFieldDecorator('bankDetails.ifsc', { rules: [{ required: true, message: 'Field is required' }] })(
              <Input disabled={formDisabled} required />,
            )}
          </FormItem>
          <FormItem label="MICR code" hasFeedback={!formDisabled} {...formItemLayout}>
            {getFieldDecorator('bankDetails.micr')(<Input disabled={formDisabled} />)}
          </FormItem>

          {mode === 'view' ? null : (
            <FormItem>
              <Button type="primary" htmlType="submit" loading={submittingForm}>
                Submit
              </Button>
            </FormItem>
          )}
        </Form>
        {mode === 'view' ? (
          <Button type="primary" htmlType="button" onClick={this.handleEditButton}>
            Edit Form
          </Button>
        ) : null}
      </div>
    );
  }
}

SellerForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFieldsAndScroll: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
  }).isRequired,
  mode: PropTypes.oneOf(['create', 'view', 'edit']),
  savedData: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  mutate: PropTypes.func.isRequired,
};

SellerForm.defaultProps = {
  savedData: null,
  mode: 'view',
};

export default compose(
  graphql(createEditSellerMutation),
  Form.create(),
)(SellerForm);
