import React, { Component } from 'react';
import { Link } from '@reach/router';
import { Button, Table, Popconfirm, message, Row, Col, Input } from 'antd';
import { Query, Mutation } from 'react-apollo';
import { log } from '../uitls/globals';

import allBuyersQuery from '../graphql/queries/allBuyersQuery.graphql';
import deleteBuyerMutation from '../graphql/mutations/deleteBuyerMutation.graphql';

const { Column } = Table;

class AllBuyersPage extends Component {
  state = {
    searchTextName: '',
    searchTextGstin: '',
  };

  handleSearchInput = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  clearSearch = () => {
    this.setState({
      searchTextName: '',
      searchTextGstin: '',
    });
  };

  render() {
    const { searchTextName, searchTextGstin } = this.state;

    return (
      <div className="contentBox">
        <h1>All Merchants</h1>
        <Link to="create">
          <Button type="primary" style={{ marginBottom: 20 }}>
            Add new merchant
          </Button>
        </Link>
        <h2>Search</h2>
        <Row style={{ marginBottom: 30 }} gutter={32}>
          <Col span={6}>
            <p style={{ marginBottom: 5 }}>Name:</p>
            <Input
              value={searchTextName}
              onChange={(e) => {
                this.handleSearchInput('searchTextName', e.target.value);
              }}
            />
          </Col>
          <Col span={6}>
            <p style={{ marginBottom: 5 }}>GSTIN:</p>
            <Input
              value={searchTextGstin}
              onChange={(e) => {
                this.handleSearchInput('searchTextGstin', e.target.value);
              }}
            />
          </Col>
          <Col span={2}>
            <Button htmlType="button" onClick={this.clearSearch} style={{ marginTop: 25 }}>
              Clear Search
            </Button>
          </Col>
        </Row>
        <Query query={allBuyersQuery} fetchPolicy="network-only">
          {({ loading, error, data }) => {
            if (error) return <p>{error.message}</p>;

            let dataSource = [];
            if (Array.isArray(data.allBuyers)) {
              dataSource = data.allBuyers.filter((entry) => {
                if (searchTextName) {
                  return entry.name.toLowerCase().includes(searchTextName.toLowerCase());
                }
                if (searchTextGstin) {
                  return entry.gstin.toLowerCase().includes(searchTextGstin.toLowerCase());
                }
                return true;
              });
            }

            return (
              <Table loading={loading} dataSource={dataSource} rowKey="id" bordered>
                <Column title="Name" key="name" dataIndex="name" />
                <Column
                  title="Address"
                  key="address"
                  render={(text, record) => (
                    <p>
                      {record.building ? record.building : null}
                      {record.building ? <br /> : null}
                      {record.road ? record.road : null}
                      {record.road ? <br /> : null}
                      {record.address ? record.address : null}
                      {record.address ? <br /> : null}
                      {record.city}, {record.state}
                      <br />
                      {record.pincode}
                    </p>
                  )}
                />
                <Column
                  title="Details"
                  key="details"
                  render={(text, record) => (
                    <div>
                      <p>
                        <strong>GSTIN: </strong>
                        {record.gstin}
                      </p>
                      <p>
                        <strong>Email: </strong>
                        {record.email}
                      </p>
                      <p>
                        <strong>Phone: </strong>
                        {record.telephone}
                      </p>
                    </div>
                  )}
                />
                <Column
                  title="Primary Contact"
                  key="primaryContact"
                  render={(text, record) => (
                    <div>
                      <p>
                        <strong>Name: </strong>
                        {record.primaryContactName}
                      </p>
                      <p>
                        <strong>Mobile: </strong>
                        {record.primaryContactMobile}
                      </p>
                      <p>
                        <strong>Email: </strong>
                        {record.primaryContactEmail}
                      </p>
                    </div>
                  )}
                />
                <Column
                  title="Actions"
                  key="actions"
                  width={150}
                  render={(text, record) => (
                    <div>
                      <Link to={record.id.toString()}>
                        <Button style={{ marginBottom: 5 }} block>
                          View
                        </Button>
                      </Link>
                      <Link to={`${record.id}/edit`}>
                        <Button style={{ marginBottom: 5 }} block>
                          Edit
                        </Button>
                      </Link>
                      <Mutation
                        mutation={deleteBuyerMutation}
                        variables={{ id: record.id }}
                        refetchQueries={['allBuyers']}
                      >
                        {(deleteBuyer) => (
                          <Popconfirm
                            title="Do you want to delete this merchant?"
                            onConfirm={() => {
                              deleteBuyer()
                                .then(() => {
                                  message.success('Merchant deleted!');
                                })
                                .catch((err) => {
                                  log(err);
                                  message.error(err.message);
                                });
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button block>Delete</Button>
                          </Popconfirm>
                        )}
                      </Mutation>
                    </div>
                  )}
                />
              </Table>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default AllBuyersPage;
