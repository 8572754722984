import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query, Mutation } from 'react-apollo';
import { Table, Button, Form, InputNumber, message, Popconfirm } from 'antd';
import { getCurrencyFormattedValue, log } from '../../uitls/globals';

import QUERY from '../../graphql/queries/todayOrdersQuery.graphql';
import editOrderMutation from '../../graphql/mutations/editOrderMutation.graphql';
import deleteOrderMutation from '../../graphql/mutations/deleteOrderMutation.graphql';

const { Column } = Table;

class TodaysOrders extends Component {
  state = {
    editId: null,
  };

  render() {
    const { editId } = this.state;
    const { form } = this.props;

    return (
      <Query query={QUERY} fetchPolicy="network-only">
        {({ loading, error, data }) => {
          if (error) return <p>ERROR: {error.message}</p>;
          return (
            <Table dataSource={data.orders} loading={loading} rowKey="id">
              <Column title="Order Id" key="id" dataIndex="id" />
              <Column
                title="Product/Brand"
                key="seller"
                render={(text, record) => (
                  <p>
                    <strong>{record.product.name}</strong>
                    <br />
                    {record.seller.name}
                  </p>
                )}
              />
              <Column title="Buyer" key="buyer" render={(text, record) => record.buyer.name} />
              <Column
                title="Quantity (qtls)"
                key="quantityQuintals"
                dataIndex="quantityQuintals"
                render={(text, record) => {
                  if (editId === record.id) {
                    return (
                      <Form.Item style={{ margin: 0 }}>
                        {form.getFieldDecorator('quantityQuintals', {
                          rules: [
                            {
                              required: true,
                              message: 'This field is required!',
                            },
                          ],
                          initialValue: record.quantityQuintals,
                        })(<InputNumber />)}
                      </Form.Item>
                    );
                  }
                  return text;
                }}
              />
              <Column
                title="Rate (per qtl)"
                key="ratePerQuintal"
                dataIndex="ratePerQuintal"
                render={(text, record) => {
                  if (editId === record.id) {
                    return (
                      <Form.Item style={{ margin: 0 }}>
                        {form.getFieldDecorator('ratePerQuintal', {
                          rules: [
                            {
                              required: true,
                              message: 'This field is required!',
                            },
                          ],
                          initialValue: record.ratePerQuintal,
                        })(<InputNumber />)}
                      </Form.Item>
                    );
                  }
                  return getCurrencyFormattedValue(text);
                }}
              />
              <Column
                title="Urgency (days)"
                key="urgencyDays"
                dataIndex="urgencyDays"
                render={(text, record) => {
                  if (editId === record.id) {
                    return (
                      <Form.Item style={{ margin: 0 }}>
                        {form.getFieldDecorator('urgencyDays', {
                          initialValue: record.urgencyDays,
                        })(<InputNumber />)}
                      </Form.Item>
                    );
                  }
                  return text;
                }}
              />
              <Column
                title="Actions"
                key="actions"
                render={(text, record) => {
                  if (editId === record.id) {
                    return (
                      <Button.Group>
                        <Mutation mutation={editOrderMutation} refetchQueries={['todayOrders']}>
                          {(editOrder) => (
                            <Button
                              htmlType="button"
                              type="primary"
                              onClick={() => {
                                form.validateFields((err, values) => {
                                  if (!err) {
                                    editOrder({ variables: { id: editId, ...values } })
                                      .then(() => {
                                        this.setState({
                                          editId: null,
                                        });
                                      })
                                      .catch((err1) => {
                                        log(err1);
                                        message.error(err1.message);
                                      });
                                  }
                                });
                              }}
                            >
                              Save
                            </Button>
                          )}
                        </Mutation>
                        <Button
                          htmlType="button"
                          type="danger"
                          onClick={() => {
                            this.setState({
                              editId: null,
                            });
                          }}
                        >
                          Cancel
                        </Button>
                      </Button.Group>
                    );
                  }
                  return (
                    <Button.Group>
                      <Button
                        htmlType="button"
                        onClick={() => {
                          this.setState({
                            editId: record.id,
                          });
                        }}
                      >
                        Edit
                      </Button>
                      <Mutation
                        mutation={deleteOrderMutation}
                        variables={{ id: record.id }}
                        refetchQueries={['todayOrders']}
                      >
                        {(deleteOrder) => (
                          <Popconfirm
                            title="Do you want to delete this order?"
                            onConfirm={() => {
                              deleteOrder()
                                .then(() => {
                                  message.success('Order deleted!');
                                })
                                .catch((err) => {
                                  log(err);
                                  message.error(err.message);
                                });
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button htmlType="button" type="danger">
                              Delete
                            </Button>
                          </Popconfirm>
                        )}
                      </Mutation>
                    </Button.Group>
                  );
                }}
              />
            </Table>
          );
        }}
      </Query>
    );
  }
}

TodaysOrders.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
  }).isRequired,
};

export default Form.create()(TodaysOrders);
