const apiBaseUrl = process.env.NODE_ENV === 'production' ? 'https://api.tejasc.in' : 'http://16.16.16.16';
const loginPagePath = '/auth/login';
const log = (data) => {
  // eslint-disable-next-line no-console
  console.log(data);
};
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    md: { span: 8 },
    lg: { span: 5 },
    xl: { span: 4 },
    xxl: { span: 3 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
    md: { span: 16 },
    lg: { span: 10 },
    xl: { span: 10 },
    xxl: { span: 6 },
  },
};
const textAreaRemoveNewline = (value) => value.trim().replace(/\r?\n|\r/g, ', ');
const removeUnwantedValuesFromObject = (obj) => {
  const newObj = {};
  Object.entries(obj).forEach((entry) => {
    const key = entry[0];
    const value = entry[1];
    if (value) newObj[key] = value;
  });
  if (Object.keys(newObj).length < 1) return null;
  return newObj;
};
const getCurrencyFormattedValue = (value) =>
  value.toLocaleString('en-IN', {
    style: 'currency',
    currency: 'INR',
    currencyDisplay: 'symbol',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

export {
  apiBaseUrl,
  loginPagePath,
  log,
  formItemLayout,
  textAreaRemoveNewline,
  removeUnwantedValuesFromObject,
  getCurrencyFormattedValue,
};
