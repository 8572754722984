import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Menu, Dropdown, Icon } from 'antd';
import { navigate, Link } from '@reach/router';
import withAuth from '../auth/withAuth';
import NavBarButton from '../components/NavBarButton';

const userMenu = (handleUserMenuClick) => (
  <Menu onClick={handleUserMenuClick}>
    <Menu.Item key="0">Logout</Menu.Item>
    <Menu.Item key="1">Update password</Menu.Item>
  </Menu>
);

class AppPage extends Component {
  handleUserMenuClick = ({ key }) => {
    if (key === '0') {
      this.props.logout();
    }
    if (key === '1') {
      navigate('/auth/password/update');
    }
  };

  render() {
    const { user, children } = this.props;
    return (
      <div className="container-fluid" style={{ minHeight: '100vh' }}>
        <div id="header" className="row justify-content-between align-items-center">
          <div className="col-auto">
            <h1 id="headerCompanyName">Tejas Canvassing</h1>
          </div>
          <div className="col-auto">
            <Dropdown overlay={userMenu(this.handleUserMenuClick)} trigger={['click']}>
              <a className="ant-dropdown-link" href="#">
                {user.name} <Icon type="down" />
              </a>
            </Dropdown>
          </div>
        </div>
        <div id="primaryNavBar" className="row">
          <div className="col-auto">
            <Link to={user.role === 'admin' ? 'admin' : 'staff'}>
              <NavBarButton>Dashboard</NavBarButton>
            </Link>
          </div>
          <div className="col-auto">
            <Link to="sellers">
              <NavBarButton>Millers</NavBarButton>
            </Link>
          </div>
          <div className="col-auto">
            <Link to="buyers">
              <NavBarButton>Merchants</NavBarButton>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-12">{children}</div>
        </div>
      </div>
    );
  }
}

AppPage.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
  }).isRequired,
  logout: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default withAuth(true)(AppPage);
