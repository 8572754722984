import React, { Fragment } from 'react';
import { Button, Card } from 'antd';
import { Link } from '@reach/router';
import onlyAdmin from '../auth/onlyAdmin';
import ProductOpenOrders from '../components/pageComponents/ProductOpenOrders';
import PlacedOrdersTable from '../components/pageComponents/PlacedOrdersTable';
import OpenSellerShipmentTable from '../components/pageComponents/OpenSellerShipmentTable';
import UnverifiedSellerShipmentsTable from '../components/pageComponents/UnverifiedSellerShipmentsTable';
import TodaysOrders from '../components/pageComponents/TodaysOrders';

const AdminDashboard = () => (
  <Fragment>
    <h1>Admin Dashboard</h1>
    <div className="row" style={{ marginBottom: 30 }}>
      <div className="col-auto">
        <Link to="/shipments/create">
          <Button htmlType="button" type="primary">
            New Shipment
          </Button>
        </Link>
      </div>
    </div>

    <Card title={<h2 style={{ margin: 0 }}>Product wise open orders</h2>} style={{ marginBottom: 30 }}>
      <ProductOpenOrders />
    </Card>

    <Card title={<h2 style={{ margin: 0 }}>Today's Orders</h2>} style={{ marginBottom: 30 }}>
      <TodaysOrders />
    </Card>

    <Card title={<h2 style={{ margin: 0 }}>Orders placed (but not shipped by miller)</h2>} style={{ marginBottom: 30 }}>
      <PlacedOrdersTable />
    </Card>

    <Card title={<h2 style={{ margin: 0 }}>Open shipments (to be delivered)</h2>} style={{ marginBottom: 30 }}>
      <OpenSellerShipmentTable />
    </Card>

    <Card title={<h2 style={{ margin: 0 }}>Unverified shipments</h2>} style={{ marginBottom: 30 }}>
      <UnverifiedSellerShipmentsTable />
    </Card>
  </Fragment>
);

export default onlyAdmin(AdminDashboard);
