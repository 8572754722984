import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from '@reach/router';
import withUserRole from '../auth/withUserRole';

const HomePage = ({ userRole }) => {
  if (userRole === 'admin') return <Redirect to="/admin" noThrow />;
  return <Redirect to="/staff" noThrow />;
};

HomePage.propTypes = {
  userRole: PropTypes.string.isRequired,
};

export default withUserRole(HomePage);
