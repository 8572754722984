import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { Spin, Table, Button, Modal } from 'antd';
import SellerForm from '../forms/SellerForm';
import ProductForm from '../forms/ProductForm';

import getSellerByIdQuery from '../graphql/queries/getSellerByIdQuery.graphql';

const { Column } = Table;

class SellerPage extends Component {
  state = {
    productFormModalVisible: false,
    productSelected: null,
  };

  closeProductFormModal = () => {
    this.setState({
      productFormModalVisible: false,
      productSelected: null,
    });
  };

  render() {
    const { id } = this.props;
    const { productFormModalVisible, productSelected } = this.state;
    return (
      <div>
        <Modal
          title="Edit product"
          closable
          destroyOnClose
          footer={null}
          visible={productFormModalVisible}
          onCancel={this.closeProductFormModal}
        >
          <ProductForm closeModal={this.closeProductFormModal} mode="edit" savedData={productSelected} />
        </Modal>
        <h1>Miller information</h1>
        <Query variables={{ id: parseInt(id, 10) }} query={getSellerByIdQuery} fetchPolicy="network-only">
          {({ loading, error, data }) => {
            if (loading) return <Spin />;
            if (error) return <p>{error.message}</p>;
            const { seller } = data;
            return (
              <div>
                <div className="contentBox">
                  <SellerForm mode="view" savedData={seller} />
                </div>
                <div className="contentBox" style={{ marginTop: 25 }}>
                  <h2>Products</h2>
                  <Table dataSource={seller.products} rowKey="id">
                    <Column title="Product/Brand name" key="name" dataIndex="name" />
                    <Column
                      title="Actions"
                      key="actions"
                      render={() => (
                        <Button.Group>
                          <Button htmlType="button">Edit</Button>
                          <Button htmlType="button">Delete</Button>
                        </Button.Group>
                      )}
                    />
                  </Table>
                </div>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

SellerPage.propTypes = {
  id: PropTypes.string,
};

SellerPage.defaultProps = {
  id: null,
};

export default SellerPage;
