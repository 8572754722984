import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Input, message, InputNumber, Table, Checkbox, DatePicker } from 'antd';
import validationRegex from '@eumentis-cloud/js-validation-regex';
import { graphql, compose } from 'react-apollo';
import { formItemLayout, log } from '../uitls/globals';

import MUTATION from '../graphql/mutations/createSellerShipmentMutation.graphql';

const FormItem = Form.Item;
const { Column } = Table;

const formItemLayoutInvoiceRight = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 8 },
    lg: { span: 19 },
    xl: { span: 19 },
    xxl: { span: 19 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 16 },
    lg: { span: 5 },
    xl: { span: 5 },
    xxl: { span: 5 },
  },
};

class CreateSellerShipmentForm extends Component {
  state = {
    submittingForm: false,
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ submittingForm: true });

    const { form, buyerId, sellerId, mutate } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const valuesToSubmit = { buyerId, sellerId, ...values };
        valuesToSubmit.dispatchDate = valuesToSubmit.dispatchDate.format('YYYY-MM-DD');
        valuesToSubmit.invoiceDate = valuesToSubmit.invoiceDate.format('YYYY-MM-DD');
        valuesToSubmit.orders = Object.values(valuesToSubmit.orders);
        valuesToSubmit.balanceFreight = valuesToSubmit.totalFreight - valuesToSubmit.advanceFreight;
        let totalShippedQuantityQuintals = 0;
        valuesToSubmit.orders.forEach((entry) => {
          if (entry.shipped) totalShippedQuantityQuintals += entry.quantityShippedQuintals;
        });
        valuesToSubmit.totalShippedQuantityQuintals = totalShippedQuantityQuintals;

        mutate({ variables: valuesToSubmit })
          .then(() => {
            this.setState({ submittingForm: false });
            message.success('Shipment created!');
            window.history.back();
          })
          .catch((mutateErr) => {
            log(mutateErr);
            message.error(mutateErr.message);
            this.setState({ submittingForm: false });
          });
      } else {
        this.setState({ submittingForm: false });
      }
    });
  };

  render() {
    const { form, orders } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    const { submittingForm } = this.state;

    return (
      <Form onSubmit={this.handleSubmit}>
        <h2>Invoice Details</h2>
        <FormItem label="Invoice No." hasFeedback {...formItemLayout}>
          {getFieldDecorator('invoiceNo', { rules: [{ required: true, message: 'Field is required' }] })(
            <Input disabled={submittingForm} required />,
          )}
        </FormItem>
        <FormItem label="Invoice Date" hasFeedback {...formItemLayout}>
          {getFieldDecorator('invoiceDate', {
            rules: [{ required: true, message: 'Field is required' }],
          })(<DatePicker disabled={submittingForm} />)}
        </FormItem>
        <Table size="middle" dataSource={orders} rowKey="id" pagination={false}>
          <Column
            title="Is order present in shipment?"
            key="shipped"
            render={(text, record) => (
              <div>
                {getFieldDecorator(`orders.${record.id}.id`, { initialValue: record.id })(
                  <Input style={{ display: 'none' }} />,
                )}
                {getFieldDecorator(`orders.${record.id}.orderedQuantityQuintals`, {
                  initialValue: record.quantityQuintals,
                })(<Input style={{ display: 'none' }} />)}
                {getFieldDecorator(`orders.${record.id}.totalQuantityShippedQuintals`, {
                  initialValue: record.totalQuantityShippedQuintals,
                })(<Input style={{ display: 'none' }} />)}
                {getFieldDecorator(`orders.${record.id}.shipped`, { initialValue: false })(
                  <Checkbox disabled={submittingForm} />,
                )}
              </div>
            )}
          />
          <Column title="Item (Product / Brand)" key="product" render={(text, record) => record.product.name} />
          <Column
            title="Quantity (qtls)"
            key="quantityShippedQuintals"
            render={(text, record) =>
              getFieldDecorator(`orders.${record.id}.quantityShippedQuintals`, {
                initialValue: record.quantityQuintals - record.totalQuantityShippedQuintals,
              })(<InputNumber disabled={submittingForm || !getFieldValue(`orders.${record.id}.shipped`)} />)
            }
          />
          <Column
            title={
              <span>
                Delivery Rate <br /> (per quintal)
              </span>
            }
            key="invoicedRatePerQuintal"
            render={(text, record) =>
              getFieldDecorator(`orders.${record.id}.invoicedRatePerQuintal`, {
                initialValue: record.ratePerQuintal,
              })(<InputNumber disabled={submittingForm || !getFieldValue(`orders.${record.id}.shipped`)} />)
            }
          />
        </Table>
        <FormItem label="GST Amount" hasFeedback {...formItemLayoutInvoiceRight} style={{ marginTop: 20 }} extra="Type 0 if GST is not applicable">
          {getFieldDecorator('invoiceGstAmount', {
            rules: [{ required: true, message: 'Field is required' }],
          })(<InputNumber disabled={submittingForm} required />)}
        </FormItem>
        <FormItem
          label="Invoice Total"
          hasFeedback
          {...formItemLayoutInvoiceRight}
          extra="After adding GST and roundoff"
        >
          {getFieldDecorator('invoiceTotal', { rules: [{ required: true, message: 'Field is required' }] })(
            <InputNumber disabled={submittingForm} required />,
          )}
        </FormItem>

        <h2 style={{ marginTop: 15 }}>Transport details</h2>
        <FormItem label="Dispatch Date" hasFeedback {...formItemLayout}>
          {getFieldDecorator('dispatchDate', {
            initialValue: getFieldValue('invoiceDate'),
            rules: [{ required: true, message: 'Field is required' }],
          })(<DatePicker disabled={submittingForm} />)}
        </FormItem>
        <FormItem label="Transport Name" hasFeedback {...formItemLayout}>
          {getFieldDecorator('transport', { rules: [{ required: true, message: 'Field is required' }] })(
            <Input disabled={submittingForm} required />,
          )}
        </FormItem>
        <FormItem label="Vehicle No." hasFeedback {...formItemLayout}>
          {getFieldDecorator('vehicleNo', { rules: [{ required: true, message: 'Field is required' }] })(
            <Input disabled={submittingForm} required />,
          )}
        </FormItem>
        <FormItem label="Driver's Name" {...formItemLayout}>
          {getFieldDecorator('driverName')(<Input disabled={submittingForm} />)}
        </FormItem>
        <FormItem label="Driver's Mobile" {...formItemLayout}>
          {getFieldDecorator('driverMobile', {
            rules: [{ pattern: validationRegex.mobile, message: 'Incorrect value' }],
          })(<Input disabled={submittingForm} addonBefore="+91" />)}
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit" loading={submittingForm}>
            Submit
          </Button>
        </FormItem>
      </Form>
    );
  }
}

CreateSellerShipmentForm.propTypes = {
  buyerId: PropTypes.number.isRequired,
  sellerId: PropTypes.number.isRequired,
  gstEnabled: PropTypes.bool,
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFieldsAndScroll: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  mutate: PropTypes.func.isRequired,
};

CreateSellerShipmentForm.defaultProps = {
  gstEnabled: false,
};

export default compose(
  graphql(MUTATION),
  Form.create(),
)(CreateSellerShipmentForm);
