import React from 'react';
import { hot } from 'react-hot-loader';
import { ApolloProvider } from 'react-apollo';
import apolloClient from './graphql/apolloClient';
import AuthProvider from './auth/AuthProviderComponent';
import Routes from './Routes';
import './scss/global.scss';

const Root = () => (
  <ApolloProvider client={apolloClient}>
    <AuthProvider persistAuth>
      <Routes />
    </AuthProvider>
  </ApolloProvider>
);

export default hot(module)(Root);
