import React from 'react';
import SellerForm from '../forms/SellerForm';

const CreateSellerPage = () => (
  <div className="contentBox">
    <h1>Add new miller</h1>
    <SellerForm mode="create" />
  </div>
);

export default CreateSellerPage;
