import ApolloClient from 'apollo-boost';

import authToken from '../auth/authToken';
import { apiBaseUrl } from '../uitls/globals';

const apolloClient = new ApolloClient({
  uri: `${apiBaseUrl}/graphql`,
  request: (operation) => {
    operation.setContext({
      headers: {
        authorization: `Bearer ${authToken.getToken()}`,
      },
    });
  },
});

export default apolloClient;
