import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, Form, Icon, Input, Button, message } from 'antd';
import validationRegex from '@eumentis-cloud/js-validation-regex';
import axios from 'axios';
import { navigate } from '@reach/router';
import { AuthConsumer } from '../auth/authContext';
import { apiBaseUrl, log } from '../uitls/globals';

const FormItem = Form.Item;

class LoginPage extends Component {
  state = {
    submittingForm: false,
  };

  handleSubmit = (login) => {
    this.setState({
      submittingForm: true,
    });

    const { form } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        axios
          .post(`${apiBaseUrl}/auth/login`, values)
          .then((response) => {
            this.setState({
              submittingForm: false,
            });
            const { updatePassword, ...user } = response.data;
            // Store auth data
            login(user);

            if (updatePassword) {
              // Go to update password page
              navigate('/auth/password/update', { replace: true });
            } else {
              // Go to app
              navigate('/', { replace: true });
            }
          })
          .catch((error) => {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              if (error.response.status === 422) {
                Object.values(error.response.data).forEach((msg) => {
                  message.error(msg);
                });
              } else if (error.response.status === 500) {
                message.error('Server error!');
              } else {
                log(error.response.data);
                log(error.response.status);
                log(error.response.headers);
              }
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              log('Error', error.message);
            }
            // console.log(error.config);
            this.setState({
              submittingForm: false,
            });
          });
      } else {
        this.setState({
          submittingForm: false,
        });
      }
    });
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { submittingForm } = this.state;

    return (
      <AuthConsumer>
        {({ login }) => (
          <div className="fullscreen">
            <Card title="Tejas Canvassing Login" style={{ width: 300 }}>
              <Form
                onSubmit={(event) => {
                  event.preventDefault();
                  this.handleSubmit(login);
                }}
              >
                <FormItem hasFeedback>
                  {getFieldDecorator('mobile', {
                    rules: [
                      { required: true, message: 'Please input your username!' },
                      { pattern: validationRegex.mobile, message: 'Incorrect Mobile' },
                    ],
                  })(
                    <Input
                      prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="Mobile"
                      disabled={submittingForm}
                    />,
                  )}
                </FormItem>
                <FormItem hasFeedback>
                  {getFieldDecorator('password', {
                    rules: [{ required: true, message: 'Please input your Password!' }],
                  })(
                    <Input
                      prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      type="password"
                      placeholder="Password"
                      disabled={submittingForm}
                    />,
                  )}
                </FormItem>
                <FormItem>
                  <Button type="primary" htmlType="submit" loading={submittingForm}>
                    Log in
                  </Button>
                </FormItem>
              </Form>
            </Card>
          </div>
        )}
      </AuthConsumer>
    );
  }
}

LoginPage.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func,
    validateFieldsAndScroll: PropTypes.func,
    resetFields: PropTypes.func,
  }).isRequired,
};

export default Form.create()(LoginPage);
