import React from 'react';
import BuyerForm from '../forms/BuyerForm';

const CreateBuyerPage = () => (
  <div className="contentBox">
    <h1>Add new merchant</h1>
    <BuyerForm mode="create" />
  </div>
);

export default CreateBuyerPage;
