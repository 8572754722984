import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { Spin } from 'antd';
import SellerForm from '../forms/SellerForm';

import getSellerByIdQuery from '../graphql/queries/getSellerByIdQuery.graphql';

const EditSellerPage = ({ id }) => (
  <div className="contentBox">
    <h1>Edit miller information</h1>
    <Query variables={{ id: parseInt(id, 10) }} query={getSellerByIdQuery} fetchPolicy="network-only">
      {({ loading, error, data }) => {
        if (loading) return <Spin />;
        if (error) return <p>{error.message}</p>;
        const { seller } = data;
        return <SellerForm mode="edit" savedData={seller} />;
      }}
    </Query>
  </div>
);

EditSellerPage.propTypes = {
  id: PropTypes.string,
};

EditSellerPage.defaultProps = {
  id: null,
};

export default EditSellerPage;
