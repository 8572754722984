import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Query, Mutation } from 'react-apollo';
import { Button, Table, message } from 'antd';
import moment from 'moment';
import withUserRole from '../../auth/withUserRole';
import { getCurrencyFormattedValue, log } from '../../uitls/globals';

import QUERY from '../../graphql/queries/openSellerShipmentsQuery.graphql';
import shipmentDeliveredMutation from '../../graphql/mutations/shipmentDeliveredMutation.graphql';

const { Column } = Table;

const OpenSellerShipmentTable = ({ userRole }) => (
  <Query query={QUERY} fetchPolicy="network-only">
    {({ loading, error, data, refetch }) => {
      if (error) return <p>ERROR: {error.message()}</p>;
      return (
        <Table dataSource={data.openSellerShipments} loading={loading} rowKey="id">
          <Column title="Seller" key="seller" dataIndex="seller.name" />
          <Column title="Buyer" key="buyer" dataIndex="buyer.name" />
          <Column
            title="Invoice Details"
            key="invoice"
            render={(text, record) => (
              <div>
                <p style={{ marginBottom: 0 }}>
                  <strong>Invoice No.: </strong>
                  {record.invoiceNo}
                </p>
                <p style={{ marginBottom: 0 }}>
                  <strong>Invoice Date: </strong>
                  {moment(record.invoiceDate, 'YYYY-MM-DD').format('Do MMM')}
                </p>
                <p style={{ marginBottom: 0 }}>
                  <strong>Invoice Total: </strong>
                  {getCurrencyFormattedValue(record.invoiceTotal)}
                </p>
              </div>
            )}
          />
          <Column
            title="Dispatch Details"
            key="dispatch"
            render={(text, record) => (
              <div>
                <p style={{ marginBottom: 0 }}>
                  <strong>Dispatch Date: </strong>
                  {moment(record.dispatchDate, 'YYYY-MM-DD').format('Do MMM')}
                </p>
                <p style={{ marginBottom: 0 }}>
                  <strong>Transport: </strong>
                  {record.transport}
                </p>
                <p style={{ marginBottom: 0 }}>
                  <strong>Vehicle No.: </strong>
                  {record.vehicleNo}
                </p>
                <p style={{ marginBottom: 0 }}>
                  <strong>Driver&apos;s Name: </strong>
                  {record.driverName}
                </p>
                <p style={{ marginBottom: 0 }}>
                  <strong>Driver&apos;s Mobile: </strong>
                  {record.driverMobile}
                </p>
              </div>
            )}
          />
          <Column
            title="Actions"
            key="actions"
            width={150}
            render={(text, record) => (
              <Fragment>
                <Mutation mutation={shipmentDeliveredMutation} variables={{ id: record.id }}>
                  {(shipmentDelivered) => (
                    <Button
                      type="primary"
                      size="small"
                      block
                      style={{ marginBottom: 7 }}
                      onClick={() => {
                        shipmentDelivered()
                          .then(() => {
                            message.success('Shipmed marked as delivered');
                            refetch();
                          })
                          .catch((mutErr) => {
                            log(mutErr);
                            message.error(mutErr.message);
                          });
                      }}
                    >
                      Mark as Delivered
                    </Button>
                  )}
                </Mutation>
                <Button type="danger" size="small" block style={{ marginBottom: 7 }}>
                  Delete shipment
                </Button>
                {userRole === 'admin' ? (
                  <Fragment>
                    <Button type="danger" size="small" block style={{ marginBottom: 7 }}>
                      Cancel shipment
                    </Button>
                    <Button size="small" block>
                      Transfer shipment
                    </Button>
                  </Fragment>
                ) : null}
              </Fragment>
            )}
          />
        </Table>
      );
    }}
  </Query>
);

OpenSellerShipmentTable.propTypes = {
  userRole: PropTypes.string.isRequired,
};

export default withUserRole(OpenSellerShipmentTable);
