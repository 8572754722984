import React from 'react';
import { Query } from 'react-apollo';
import { Table } from 'antd';
import moment from 'moment';
import { getCurrencyFormattedValue } from '../../uitls/globals';

import QUERY from '../../graphql/queries/placedOrdersQuery.graphql';

const { Column } = Table;

const PlacedOrdersTable = () => (
  <Query query={QUERY} fetchPolicy="network-only">
    {({ loading, error, data }) => {
      if (error) return <p>ERROR: {error.message()}</p>;
      return (
        <Table dataSource={data.placedOrders} loading={loading} rowKey="id">
          <Column title="Order Id" key="id" dataIndex="id" />
          <Column
            title="Order Placed On"
            key="placedOn"
            dataIndex="placedOn"
            render={(text) => moment(new Date(parseFloat(text))).format('Do MMM, YYYY')}
          />
          <Column
            title="Product/Brand"
            key="seller"
            render={(text, record) => (
              <p>
                <strong>{record.product.name}</strong>
                <br />
                {record.seller.name}
              </p>
            )}
          />
          <Column title="Buyer" key="buyer" render={(text, record) => record.buyer.name} />
          <Column title="Quantity (qtls)" key="quantityQuintals" dataIndex="quantityQuintals" />
          <Column
            title="Rate (per qtl)"
            key="ratePerQuintal"
            dataIndex="ratePerQuintal"
            render={(text) => getCurrencyFormattedValue(text)}
          />
          <Column title="Urgency (days)" key="urgencyDays" dataIndex="urgencyDays" />
        </Table>
      );
    }}
  </Query>
);

export default PlacedOrdersTable;
