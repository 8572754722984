import React from 'react';
import { Redirect } from '@reach/router';
import { AuthConsumer } from './authContext';

const onlyAdmin = (Component) => (props) => (
  <AuthConsumer>
    {({ user }) => {
      if (user.role !== 'admin') return <Redirect to="/" noThrow />;
      return <Component {...props} />;
    }}
  </AuthConsumer>
);

export default onlyAdmin;
