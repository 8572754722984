import React, { Component, Fragment } from 'react';
import { Query, Mutation } from 'react-apollo';
import { Select, Spin, Table, Button, message } from 'antd';
import moment from 'moment';
import { getCurrencyFormattedValue } from '../../uitls/globals';

import openOrdersProductsQuery from '../../graphql/queries/openOrdersProductsQuery.graphql';
import openOrdersByProductQuery from '../../graphql/queries/openOrdersByProductQuery.graphql';
import placeOrdersMutation from '../../graphql/mutations/placeOrdersMutation.graphql';

const { Option } = Select;
const { Column } = Table;

class ProductOpenOrders extends Component {
  state = {
    openOrdersSelectedProduct: undefined,
    selectedOrdersToBePlaced: [],
  };

  handleOpenOrderProductSelect = (value) => {
    this.setState({ openOrdersSelectedProduct: value });
  };

  ordersToPlaceTableOnRowSelection = (selectedRowKeys) => {
    this.setState({ selectedOrdersToBePlaced: selectedRowKeys });
  };

  render() {
    const { openOrdersSelectedProduct, selectedOrdersToBePlaced } = this.state;
    return (
      <Fragment>
        <Query query={openOrdersProductsQuery} fetchPolicy="network-only">
          {({ loading, error, data }) => {
            if (loading) return <Spin size="small" />;
            if (error) return <p>ERROR: {error.message()}</p>;
            const { openOrdersProducts } = data;
            return (
              <Fragment>
                {!openOrdersSelectedProduct ? <p>Select a product/brand to see open orders</p> : null}
                <Select
                  allowClear
                  style={{ width: 500 }}
                  showSearch
                  optionFilterProp="children"
                  onChange={this.handleOpenOrderProductSelect}
                  value={openOrdersSelectedProduct}
                  placeholder="Select Product / Brand"
                >
                  {openOrdersProducts.map((entry) => {
                    let value = `${entry.id}_null_null_null`;
                    if (Array.isArray(entry.dailyRates) && entry.dailyRates.length > 0) {
                      const dr = entry.dailyRates[0];
                      value = `${entry.id}_${dr.ratePerQuintal}_${dr.minQuintals}_${dr.maxQuintals}`;
                    }
                    return (
                      <Option key={entry.id} value={value}>
                        {entry.name} ({entry.seller.name})
                      </Option>
                    );
                  })}
                </Select>
              </Fragment>
            );
          }}
        </Query>
        {openOrdersSelectedProduct ? (
          <Query
            query={openOrdersByProductQuery}
            variables={{ productId: parseInt(openOrdersSelectedProduct.split('_')[0], 10) }}
            fetchPolicy="network-only"
          >
            {({ loading, error, data }) => {
              if (error) return <p>ERROR: {error.message()}</p>;
              const { openOrdersByProduct } = data;
              const prodArr = openOrdersSelectedProduct.split('_');
              const lastPrice = parseFloat(prodArr[1]);
              const minQuintal = parseFloat(prodArr[2]);
              const maxQuintal = parseFloat(prodArr[3]);
              let totalQty = 0;
              if (Array.isArray(openOrdersByProduct) && openOrdersByProduct.length > 0) {
                openOrdersByProduct.forEach((entry1) => {
                  if (selectedOrdersToBePlaced.includes(entry1.id)) totalQty += entry1.quantityQuintals;
                });
              }
              return (
                <Fragment>
                  {lastPrice ? (
                    <p style={{ marginTop: 15, marginBottom: 0 }}>
                      <strong>Today's Price: </strong>
                      {getCurrencyFormattedValue(lastPrice)}
                    </p>
                  ) : null}
                  <h3 style={{ marginTop: 15 }}>Open Orders</h3>
                  <p style={{ fontStyle: 'italic' }}>Select the orders that you want to place.</p>
                  <Table
                    loading={loading}
                    dataSource={openOrdersByProduct}
                    rowKey="id"
                    rowSelection={{
                      selectedRowKeys: selectedOrdersToBePlaced,
                      onChange: this.ordersToPlaceTableOnRowSelection,
                    }}
                  >
                    <Column title="Order Id" key="id" dataIndex="id" />
                    <Column
                      title="Order Date"
                      key="createdAt"
                      render={(text, record) => moment(new Date(parseFloat(record.createdAt))).format('Do MMM, YYYY')}
                    />
                    <Column title="Buyer" key="buyer" render={(text, record) => record.buyer.name} />
                    <Column title="Quantity (qtls)" key="quantityQuintals" dataIndex="quantityQuintals" />
                    <Column
                      title="Rate (per qtl)"
                      key="ratePerQuintal"
                      dataIndex="ratePerQuintal"
                      render={(text) => getCurrencyFormattedValue(text)}
                    />
                    <Column title="Urgency (days)" key="urgencyDays" dataIndex="urgencyDays" />
                  </Table>
                  <div className="row" style={{ marginBottom: 10 }}>
                    <div className="col-auto">
                      <strong>Total Quantity (selected): </strong>
                      {totalQty} qtls
                    </div>
                    {minQuintal ? (
                      <div className="col-auto">
                        <strong>Min Quantity: </strong>
                        {minQuintal} qtls
                      </div>
                    ) : null}
                    {maxQuintal ? (
                      <div className="col-auto">
                        <strong>Max Quantity: </strong>
                        {maxQuintal} qtls
                      </div>
                    ) : null}
                  </div>
                  <Mutation
                    mutation={placeOrdersMutation}
                    variables={{ orderIds: selectedOrdersToBePlaced }}
                    onError={(mutErr) => {
                      message.error(mutErr.message);
                    }}
                    onCompleted={() => {
                      message.success('Orders placed!');
                      this.setState({
                        openOrdersSelectedProduct: undefined,
                        selectedOrdersToBePlaced: [],
                      });
                    }}
                  >
                    {(placeOrders, { loading: mutLoading }) => (
                      <Button
                        htmlType="button"
                        type="primary"
                        disabled={selectedOrdersToBePlaced.length < 1}
                        onClick={placeOrders}
                        loading={mutLoading}
                      >
                        Place Order
                      </Button>
                    )}
                  </Mutation>
                </Fragment>
              );
            }}
          </Query>
        ) : null}
      </Fragment>
    );
  }
}

export default ProductOpenOrders;
