import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import './NavBarButton.scss';

const NavBarButton = (props) => (
  <Button className="ecNavBarButton" type="primary" {...props}>
    {props.children}
  </Button>
);

NavBarButton.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NavBarButton;
