import React from 'react';
import { Spin } from 'antd';
import { Redirect } from '@reach/router';
import { AuthConsumer } from './authContext';
import FullPageLoading from '../components/FullPageLoading';

import { loginPagePath } from '../uitls/globals';

const withAuth = (fullPageLoading = false) => (Component) => (props) => (
  <AuthConsumer>
    {({ userLoggedIn, user, loading, logout }) => {
      if (loading) {
        if (fullPageLoading) return <FullPageLoading />;
        return <Spin />;
      }
      if (!userLoggedIn) return <Redirect to={loginPagePath} noThrow />;
      return <Component {...props} user={user} logout={logout} />;
    }}
  </AuthConsumer>
);

export default withAuth;
