import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Input, message } from 'antd';
import { graphql, compose } from 'react-apollo';
import { log } from '../uitls/globals';

import createEditProductMutation from '../graphql/mutations/createEditProductMutation.graphql';
import allSellersQuery from '../graphql/queries/allSellersQuery.graphql';

const FormItem = Form.Item;

class ProductForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submittingForm: false,
      mode: props.mode,
    };
  }

  componentDidMount() {
    const { savedData, form } = this.props;
    if (savedData) {
      const { id, __typename, createdAt, ...formData } = savedData;
      form.setFieldsValue(formData);
    }
  }

  handleEditButton = () => {
    this.setState({ mode: 'edit' });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ submittingForm: true });

    const { form, mutate, savedData, sellerId, closeModal } = this.props;
    const { mode } = this.state;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const valuesToSubmit = { ...values };
        if (savedData && Number.isInteger(savedData.id) && mode === 'edit') valuesToSubmit.id = savedData.id;
        if (mode === 'create') valuesToSubmit.sellerId = sellerId;

        const mutateOptions = { variables: valuesToSubmit };
        if (mode === 'create') {
          mutateOptions.update = (proxyClient, { data: { product } }) => {
            const data = proxyClient.readQuery({ query: allSellersQuery });
            data.allSellers = data.allSellers.map((entry) => {
              if (entry.id == sellerId) {
                if (Array.isArray(entry.products)) return { ...entry, products: [...entry.products, product] };
                return { ...entry, products: [product] };
              }
              return entry;
            });
            proxyClient.writeQuery({ query: allSellersQuery, data });
          };
        }

        mutate(mutateOptions)
          .then(() => {
            this.setState({ submittingForm: false, mode: 'view' });
            message.success('Product created!');
            if (closeModal) closeModal();
          })
          .catch((mutateErr) => {
            log(mutateErr);
            message.error(mutateErr.message);
            this.setState({ submittingForm: false });
          });
      } else {
        this.setState({ submittingForm: false });
      }
    });
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { submittingForm, mode } = this.state;

    const formDisabled = submittingForm || mode === 'view';

    return (
      <div>
        <Form onSubmit={this.handleSubmit}>
          <FormItem label="Product/Brand Name" hasFeedback={!formDisabled}>
            {getFieldDecorator('name', {
              rules: [{ required: true, message: 'Field is required' }],
            })(<Input disabled={formDisabled} required />)}
          </FormItem>

          {mode === 'view' ? null : (
            <FormItem>
              <Button type="primary" htmlType="submit" loading={submittingForm}>
                Submit
              </Button>
            </FormItem>
          )}
        </Form>
        {mode === 'view' ? (
          <Button type="primary" htmlType="button" onClick={this.handleEditButton}>
            Edit
          </Button>
        ) : null}
      </div>
    );
  }
}

ProductForm.propTypes = {
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    validateFieldsAndScroll: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
  }).isRequired,
  mode: PropTypes.oneOf(['create', 'view', 'edit']),
  savedData: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  mutate: PropTypes.func.isRequired,
  sellerId: PropTypes.number,
  closeModal: PropTypes.func,
};

ProductForm.defaultProps = {
  savedData: null,
  mode: 'view',
  sellerId: null,
  closeModal: null,
};

export default compose(
  graphql(createEditProductMutation),
  Form.create(),
)(ProductForm);
